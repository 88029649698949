import React from 'react';
import styled, { css } from 'styled-components';
import SocialIcon from '../../atoms/SocialIcon';
import { SOCIAL_ITEMS } from '../../config/social';
import { mobile } from '../../utils/media';

const FooterSocialsWrapper = styled.div`
  text-align: right;
  margin-top: 20px;

  div {
    margin-left: 30px;
  }

  ${mobile(css`
    text-align: center;
    margin-top: 10px;
    div {
      margin-right: 15px;
    }
  `)}
`;

const FooterSocials = () => {
  const socialIcons = Object.keys(SOCIAL_ITEMS).map((socialKey) => (
    <SocialIcon
      key={socialKey}
      socialIcon={SOCIAL_ITEMS[socialKey].iconName}
      socialLink={SOCIAL_ITEMS[socialKey].socialLink}
    />
  ));

  return <FooterSocialsWrapper>{socialIcons}</FooterSocialsWrapper>;
};

FooterSocials.propTypes = {};

export default FooterSocials;
