import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { URLS } from '../config/constants';

const SEO = ({ pageTitle, description, pathname, image }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, twitterUsername },
      },
    }) => {
      const seo = {
        title: pageTitle.toUpperCase() || defaultTitle,
        description: description || defaultDescription,
        url: `${URLS.landingSiteUrl}${pathname === 'home' ? '' : pathname}`,
        imageUrl: `${URLS.resources}images/landing/${image}`,
      };

      //      console.log('seo.url', 'xxx', process.env.GATSBY_LANDING_SITE_URL, URLS.landingSiteUrl, 'xxx', seo.url)

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            {seo.title && <meta property="og:title" content={seo.title} />}
            <meta property="og:site_name" content={defaultTitle} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {/** TODO - pass page images from individual pages */}
            {seo.image && <meta property="og:image" content={seo.imageUrl} />}

            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername &&
              ((<meta name="twitter:creator" content={twitterUsername} />),
              (<meta name="twitter:site" content={twitterUsername} />))}
          </Helmet>
        </>
      );
    }}
  />
);

export default SEO;

SEO.propTypes = {
  pageTitle: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
};

SEO.defaultProps = {
  pageTitle: null,
  description: null,
  pathname: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        twitterUsername
      }
    }
  }
`;
