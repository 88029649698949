import { Link } from 'gatsby';
import React from 'react';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { CenteredRowGrid } from '../assets/styles/Utils';
import { COLORS } from '../assets/styles/variables';
import FontIcon from '../atoms/FontIcon';
import NavItem, { navBorderHeight } from '../atoms/NavItem';
import Text from '../atoms/Text';
import { solutionsItems } from '../config/cardsContents';
import { NAV_ITEMS } from '../config/constants';
import { mobile } from '../utils/media';

const NavContainer = styled(CenteredRowGrid)`
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 0 20px;
  width: 55%;

  ${mobile(css`
    display: none;
  `)}
`;

const ArrowIcon = styled(FontIcon)`
  transition: 100ms all linear;
  position: relative;
  left: 15px;
`;

const DropdownNavItem = styled.div`
  transition: all 300ms;
  padding: ${navBorderHeight} 20px 0;
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-auto-flow: column;
  border-bottom: ${navBorderHeight} solid transparent;
  cursor: pointer;

  &.selected {
    border-bottom: ${navBorderHeight} solid ${COLORS.bgLight};
  }

  &:hover {
    border-bottom: ${navBorderHeight} solid ${COLORS.bgLight};
  }
`;

const DropdownNavWrapper = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 150;
  width: 100%;
  top: 100%;
  background-color: ${COLORS.bgLight};
  box-shadow: 0 2px 10px ${COLORS.shadowDark};
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  transition: all 300ms;
  color: ${COLORS.txtDark};
  display: block;
  justify-items: center;

  &:hover ${ArrowIcon} {
    transform: rotate(180deg);
  }
`;

const DropdownNavListItem = styled.div`
  border-bottom: 1px solid ${COLORS.border};
  padding: 8px 20px;
  width: 100%;
  transition: all 300ms;
  display: block;
  text-align: center;

  &.last-item {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${COLORS.sectionBg};
  }
`;

const DropdownNavItemWrapper = styled.div`
  position: relative;
  height: 100%;

  &:hover ${DropdownNavWrapper} {
    visibility: visible;
    opacity: 1;
  }

  &:hover ${ArrowIcon} {
    transform: rotate(180deg);
  }
`;
class Nav extends React.Component {
  state = {
    currentSelectedPath: '',
  };

  componentDidMount() {
    const currentPath = window.location.pathname.substr(1, window.location.pathname.length - 2).toLocaleLowerCase();
    this.setState({ currentSelectedPath: currentPath });
  }

  render() {
    const { t } = this.props;

    const solutionsNavListItem =
      solutionsItems &&
      solutionsItems.map((item, i) => (
        <Link key={item.labelKey} to={item.linkTo}>
          <DropdownNavListItem className={i === solutionsItems.length - 1 ? 'last-item' : ''}>
            <Text weight={'normal'} transform={'uppercase'} color={COLORS.primary} lineHeight={2}>
              {t(`app:nav:${item.id}`)}
            </Text>
          </DropdownNavListItem>
        </Link>
      ));

    return (
      <NavContainer>
        <NavItem
          isSelected={NAV_ITEMS.home.id === this.state.currentSelectedPath || this.state.currentSelectedPath === ''}
          linkTo={NAV_ITEMS.home.linkTo}
        >
          {t(NAV_ITEMS.home.labelKey)}
        </NavItem>
        <DropdownNavItemWrapper>
          <DropdownNavItem
            className={
              this.state.currentSelectedPath === NAV_ITEMS.crops.id ||
              this.state.currentSelectedPath === NAV_ITEMS.livestock.id
                ? 'selected'
                : ''
            }
          >
            <Text transform={'uppercase'}>{t('app:nav:solutions')}</Text>
            <ArrowIcon
              //              style={{ transition: '80ms all linear', position: 'relative', left: 15 }}
              block
              color={COLORS.txtLight}
              icon={'arrow2-down'}
              size={'14px'}
            />
          </DropdownNavItem>
          <DropdownNavWrapper>{solutionsNavListItem}</DropdownNavWrapper>
        </DropdownNavItemWrapper>
        <NavItem
          isSelected={NAV_ITEMS.contactUs.id === this.state.currentSelectedPath}
          linkTo={NAV_ITEMS.contactUs.linkTo}
        >
          {t(NAV_ITEMS.contactUs.labelKey)}
        </NavItem>
        <NavItem isSelected={NAV_ITEMS.about.id === this.state.currentSelectedPath} linkTo={NAV_ITEMS.about.linkTo}>
          {t(NAV_ITEMS.about.labelKey)}
        </NavItem>
      </NavContainer>
    );
  }
}

Nav.propTypes = {};

Nav.defaultProps = {};

export default withTranslation()(Nav);
