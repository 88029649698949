import {SOLUTIONS_HOME_CARDS, URLS} from './constants';

export const PROFESSIONALIST_HOME_CARDS = {
  farmer: {id: 'farmer', labelKey: 'app:nav:farmer', linkTo: '/farmer/', image: 'farmer-personas.png'},
  dealer: {id: 'dealer', labelKey: 'app:nav:dealer', linkTo: '/dealer/', image: 'dealer-personas.png'},
  dairyFarmer: {
    id: 'dairyFarmer',
    labelKey: 'app:nav:dairyFarmer',
    linkTo: '/dairy-farmer/',
    image: 'dairyFarmer-personas.jpg',
  },
  agronomist: {
    id: 'agronomist',
    labelKey: 'app:nav:agronomist',
    linkTo: '/agronomist/',
    image: 'agronomist-personas.png',
  },
};

export const professionistItems = Object.values(PROFESSIONALIST_HOME_CARDS);
export const solutionsItems = Object.values(SOLUTIONS_HOME_CARDS);

export const DEALER_CARDS = [
  {image: 'cards/fields-overview.png'},
  {image: 'cards/fields-tasks.png'},
  {image: 'cards/dashboard.png'},
];

export const FARMER_CARDS = [
  {image: 'cards/fields-overview.png'},
  {image: 'cards/fields-tasks.png'},
  {image: 'cards/dashboard.png'},
];

export const AGRONOMIST_CARDS = [
  {image: 'cards/fields-overview.png'},
  {image: 'cards/fields-tasks.png'},
  {image: 'cards/dashboard.png'},
];

export const DAIRY_FARMER_CARDS = [
  {image: 'cards/software.jpg'},
  {image: 'cards/app.jpg'},
  {image: 'cards/indicators.jpg'},
];

export const DAIRY_FARMER_SCREEN_CARDS = [
  {image: 'screens/screen-manage-products.jpg'},
  {image: 'screens/screen-feed-groups.jpg'},
  {image: 'screens/screen-feeding-lists.jpg'},
  {image: 'screens/screen-load-mixers.jpg'},
  {image: 'screens/screen-reports.jpg'},
  {image: 'screens/screen-manage.jpg'},
  {image: 'screens/screen-sharing.jpg'},
];

export const HOME_ADVANTAGES_CARDS = [
  {
    id: 'advantage1',
    image: 'home/advantages/advantage1.png',
  },
  {
    id: 'advantage2',
    image: 'home/advantages/advantage2.png',
  },
  {
    id: 'advantage3',
    image: 'home/advantages/advantage3.png',
  },
  {
    id: 'advantage4',
    image: 'home/advantages/advantage4.png',
  },
];

export const CROPS_PRODUCT_CARDS = [
  {
    id: 'fields',
    image: 'crops/cards/fields.png',
    actions: [
      {label: 'app:home:free_trial', linkTo: URLS.signup + '?areaOfInterest=farmer'},
      {label: 'app:home:learn_more', linkTo: '#explore'},
    ],
  },
  {
    id: 'fleet',
    image: 'crops/cards/fleet.png',
    note: 'app:crops:products:cloudlink_required',
    actions: [{label: 'app:home:learn_more', linkTo: '#explore'}],
  },
  {
    id: 'pro',
    image: 'crops/cards/pro.png',
    note: 'app:crops:products:cloudlink_required',
    actions: [{label: 'app:home:learn_more', linkTo: '#explore'}],
  },
];

export const LIVESTOCK_PRODUCT_CARDS = [
  {
    id: 'feed-app',
    image: 'livestock/cards/feed-app.png',
    actions: [],
    appStore: URLS.feedAppAppleStoreUrl,
    googlePlay: URLS.feedAppGooglePlayUrl,
  },
  {
    id: 'feed-lite',
    image: 'livestock/cards/feed-lite.png',
    actions: [
      {label: 'app:home:free_trial', linkTo: URLS.signup + '?areaOfInterest=breeder'},
      {
        label: 'app:home:learn_more', linkTo: '#explore', assets: [
          {
            label: 'app:explore:feed_management_book',
            download: URLS.resources + 'documents/landing/Topcon Agriculture Feed Management.pdf'
          },
          {
            label: 'app:explore:feed_management_feature_book',
            download: URLS.resources + 'documents/landing/TAP - Digital Booklet - FEED_EN.pdf'
          },
          {
            label: 'app:explore:feed_subscriptions_book',
            download: URLS.resources + 'documents/landing/TAP FEED Subscriptions.pdf'
          },
          {
            label: 'app:explore:feed_feature_book',
            download: URLS.resources + 'documents/landing/TAP FEED Feature Comparison.pdf'
          },
          {
            label: 'app:explore:feed_hardware_book',
            download: URLS.resources + 'documents/landing/TAP FEED Hardware Capability.pdf'
          },
        ]
      },
    ],
  },
  {
    id: 'feed-pro',
    image: 'livestock/cards/feed-pro.png',
    actions: [
      {label: 'app:home:free_trial', linkTo: URLS.signup + '?areaOfInterest=breeder'},
      {
        label: 'app:home:learn_more', linkTo: '#explore', assets: [
          {
            label: 'app:explore:feed_management_book',
            download: URLS.resources + 'documents/landing/Topcon Agriculture Feed Management.pdf'
          },
          {
            label: 'app:explore:feed_management_feature_book',
            download: URLS.resources + 'documents/landing/TAP - Digital Booklet - FEED_EN.pdf'
          },
          {
            label: 'app:explore:feed_subscriptions_book',
            download: URLS.resources + 'documents/landing/TAP FEED Subscriptions.pdf'
          },
          {
            label: 'app:explore:feed_feature_book',
            download: URLS.resources + 'documents/landing/TAP FEED Feature Comparison.pdf'
          },
          {
            label: 'app:explore:feed_hardware_book',
            download: URLS.resources + 'documents/landing/TAP FEED Hardware Capability.pdf'
          },
        ]
      },
    ],
  },
  {
    id: 'feed-feedlot',
    image: 'livestock/cards/feed-feedlot.png',
    actions: [
      {label: 'app:home:free_trial', linkTo: URLS.signup + '?areaOfInterest=breeder'},
      {
        label: 'app:home:learn_more', linkTo: '#explore', assets: [
          {
            label: 'app:explore:feed_management_book',
            download: URLS.resources + 'documents/landing/Topcon Agriculture Feed Management.pdf'
          },
          {
            label: 'app:explore:feed_management_feature_book',
            download: URLS.resources + 'documents/landing/TAP - Digital Booklet - FEED_EN.pdf'
          },
          {
            label: 'app:explore:feed_subscriptions_book',
            download: URLS.resources + 'documents/landing/TAP FEED Subscriptions.pdf'
          },
          {
            label: 'app:explore:feed_feature_book',
            download: URLS.resources + 'documents/landing/TAP FEED Feature Comparison.pdf'
          },
          {
            label: 'app:explore:feed_hardware_book',
            download: URLS.resources + 'documents/landing/TAP FEED Hardware Capability.pdf'
          },
        ]
      },
    ],
  },
  {
    id: 'feed-advisors',
    image: 'livestock/cards/feed-advisors.png',
    actions: [
      {label: 'app:contact_us', linkTo: '/contact-us'},
      {
        label: 'app:home:learn_more', linkTo: '#explore', assets: [
          {
            label: 'app:explore:feed_management_book',
            download: URLS.resources + 'documents/landing/Topcon Agriculture Feed Management.pdf'
          },
          {
            label: 'app:explore:feed_management_feature_book',
            download: URLS.resources + 'documents/landing/TAP - Digital Booklet - FEED_EN.pdf'
          },
          {label: 'app:explore:feed_advisor_book', download: URLS.resources + 'documents/landing/TAP FEED Advisor.pdf'},
          {
            label: 'app:explore:feed_subscriptions_book',
            download: URLS.resources + 'documents/landing/TAP FEED Subscriptions.pdf'
          },
          {
            label: 'app:explore:feed_feature_book',
            download: URLS.resources + 'documents/landing/TAP FEED Feature Comparison.pdf'
          },
          {
            label: 'app:explore:feed_hardware_book',
            download: URLS.resources + 'documents/landing/TAP FEED Hardware Capability.pdf'
          },
        ]
      },
    ],
  },
];

export const CROPS_ADDONS_CARDS = [
  {
    id: 'grain',
    image: 'cards/grain-cart.png',
    note: 'app:crops:products:cloudlink_required',
    actions: [],
  },
  {
    id: 'nrate',
    image: 'cards/nrate.png',
    note: 'app:crops:products:pro_required',
    actions: [],
  },
];

export const LIVESTOCK_ADDONS_CARDS = [
  {
    id: 'link',
    image: 'cards/link.png',
    actions: [{
      label: 'app:home:learn_more', linkTo: '', assets: [
        {label: 'app:explore:feed_link_book', download: URLS.resources + 'documents/landing/TAP Feed Link.pdf'},
      ]
    }],
  },
  {
    id: 'inventory',
    image: 'cards/inventory.png',
    actions: [],
  },
];

export const CROPS_FEATURES_CARDS = [
  {
    id: 'fields',
    image: 'crops/features/fields.svg',
  },
  {
    id: 'dashboard',
    image: 'crops/features/dashboard.svg',
  },
  {
    id: 'storage',
    image: 'crops/features/storage.svg',
  },
  {
    id: 'support',
    image: 'crops/features/support.svg',
  },
  {
    id: 'sync',
    image: 'crops/features/sync.png',
  },
  {
    id: 'fleet',
    image: 'crops/features/fleet.svg',
  },
];

export const LIVESTOCK_FEATURES_CARDS = [
  {
    id: 'reduce-inputs',
    image: 'livestock/features/reduce-inputs.png',
  },
  {
    id: 'increase-production',
    image: 'livestock/features/increase-production.png',
  },
];

export const CROPS_BOOKLETS = [
  {
    id: 'aftermarket',
    image: {link: 'crops/booklets/tap-aftermarket-digital-booklet.png', isLocal: true},
    action: {
      linkTo: URLS.aftermarketPdfUrl,
      label: 'app:download',
    },
  },
  /*   {
    id: 'oem',
    image: 'crops/booklets/tap-oem-digital-booklet.png',
    action: {
      linkTo: '',
      label: 'app:download',
    },
  }, */
];

export const LIVESTOCK_BOOKLETS = [
  {
    id: 'feed',
    image: {link: 'livestock/booklets/tap-feed-digital-booklet.png', isLocal: true},
    action: {
      linkTo: URLS.feedPdfUrl,
      label: 'app:download',
    },
  },
];

export const CROPS_VIDEOS = [
  {
    id: 'data',
    image: {link: 'https://img.youtube.com/vi/cWfv1RICcts/0.jpg', isLocal: false},
    action: {
      linkTo: 'https://youtu.be/cWfv1RICcts',
      label: 'app:youtube',
    },
  },
  {
    id: 'agronomy',
    image: {link: 'https://img.youtube.com/vi/8iBqXvx6aVI/0.jpg', isLocal: false},
    action: {
      linkTo: 'https://youtu.be/8iBqXvx6aVI',
      label: 'app:youtube',
    },
  },
];

export const LIVESTOCK_VIDEOS = [
  {
    id: 'feed-data',
    image: {link: 'https://img.youtube.com/vi/OVwG4iO-w8E/0.jpg', isLocal: false},
    action: {
      linkTo: 'https://youtu.be/OVwG4iO-w8E',
      label: 'app:youtube',
    },
  },
];
