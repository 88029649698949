import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { CenteredRowGrid } from '../../assets/styles/Utils';
import { COLORS } from '../../assets/styles/variables';

const SectionHeaderContainer = styled(CenteredRowGrid)`
  padding: 0 5%;
  width: 100%;
  color: ${COLORS.txtDark};
  background-color: ${({ bgColor }) => bgColor || COLORS.bgLight};
  text-align: center;
  word-break: break-word;
`;

const SectionHeaderContentWrapper = styled.div`
  margin: auto;
  max-width: 1440px;
  padding: 40px 3%;
`;

const SectionHeader = ({ bgColor, children }) => (
  <SectionHeaderContainer bgColor={bgColor}>
    <SectionHeaderContentWrapper>{children}</SectionHeaderContentWrapper>
  </SectionHeaderContainer>
);

SectionHeader.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SectionHeader.defaultProps = {};

export default SectionHeader;
