import * as cn from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { BtnTypes } from '../assets/styles/Utils';
import { COLORS } from '../assets/styles/variables';
import { phone } from '../utils/media';

const ButtonContainer = styled.button`
  padding: ${(props) => (props.big ? '16px 30px' : '10px 25px')};
  font-size: ${(props) => (props.big ? '18px' : '15px')};
  transition: all 100ms linear;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  outline: none;
  text-transform: uppercase;
  font-weight: bold;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  min-width: 100px;

  + button {
    margin-left: 20px;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

const PrimaryButton = styled(ButtonContainer)`
  background-color: ${(props) => (props.bgColor ? props.bgColor : COLORS.brand)};
  color: ${(props) => (props.textColor ? props.textColor : COLORS.txtLight)};

  &.disabled {
    background-color: ${COLORS.bgDark};
    box-shadow: none;
  }

  &:hover {
    text-decoration: underline;
    background-color: ${COLORS.brandHover};
  }
  &:active {
    background-color: ${COLORS.brand};
    box-shadow: inset 1px 1px 8px ${COLORS.shadow};
  }
`;

const SecondaryButton = styled(ButtonContainer)`
  background-color: ${(props) => (props.bgColor ? props.bgColor : COLORS.brand)};
  color: ${(props) => (props.textColor ? props.textColor : COLORS.txtLight)};
  box-shadow: ${(props) => (props.noShadow ? 'none' : `0 1px 5px ${COLORS.shadowDark}`)};

  &.disabled {
    background-color: ${COLORS.sectionBg};
    color: ${COLORS.shadowLight};
    box-shadow: none;
    opacity: 0.5;
  }

  &:hover {
    text-decoration: underline;
  }
  &:active {
    box-shadow: inset 1px 1px 8px ${COLORS.shadow};
  }
`;

const OutlinedButton = styled(ButtonContainer)`
  border: 1px solid ${COLORS.txtLight};
  background-color: transparent;
  box-shadow: none;
  color: ${COLORS.txtLight};

  &:hover {
    text-decoration: underline;
  }
  &:active {
  }
`;

const OutlinedSecondaryButton = styled(ButtonContainer)`
  border: 2px solid ${COLORS.brand};
  background-color: transparent;
  box-shadow: none;
  color: ${COLORS.brand};

  &:hover {
    text-decoration: underline;
  }
`;

const GooglePlayButton = styled(ButtonContainer)`
  height: ${(props) => (props.big ? '70px' : '60px')};
  min-width: 180px;
  width: 80%;
  background: url(${require('../assets/images/google-play.png')}), black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  ${phone(css`
    margin: 10px 0;
  `)};
`;

const AppleStoreButton = styled(ButtonContainer)`
  height: ${(props) => (props.big ? '70px' : '60px')};
  min-width: 180px;
  width: 80%;
  background: url(${require('../assets/images/app-store.png')}), black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  ${phone(css`
    margin: 10px 0;
  `)};
`;

const Button = ({ role, children, big, type, onClick, disabled, className, fullWidth, ...props }) => {
  const btnMap = {
    [BtnTypes.primary]: PrimaryButton,
    [BtnTypes.secondary]: SecondaryButton,
    [BtnTypes.outlined]: OutlinedButton,
    [BtnTypes.outlinedSecondary]: OutlinedSecondaryButton,
    [BtnTypes.googlePlay]: GooglePlayButton,
    [BtnTypes.appStore]: AppleStoreButton,
  };

  const RequiredButton = btnMap[role];

  const classes = cn(className, disabled && 'disabled');

  return (
    <RequiredButton
      type={type}
      big={big}
      onClick={onClick}
      disabled={disabled}
      className={classes}
      fullWidth={fullWidth}
      {...props}
    >
      {children}
    </RequiredButton>
  );
};

Button.propTypes = {
  role: PropTypes.oneOf([
    BtnTypes.primary,
    BtnTypes.secondary,
    BtnTypes.outlined,
    BtnTypes.outlinedSecondary,
    BtnTypes.googlePlay,
    BtnTypes.appStore,
  ]),
  big: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noShadow: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
};

Button.defaultProps = {
  role: 'primary',
  big: false,
  noShadow: false,
  fullWidth: false,
};

export default Button;
