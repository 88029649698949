export const URLS = {
  resources: process.env.GATSBY_RESOURCES_URL,
  signup: process.env.GATSBY_SIGNUP_URL,
  login: process.env.GATSBY_LOGIN_URL,
  fallbackLogin: process.env.GATSBY_FALLBACK_LOGIN_URL,
  developerPortal: process.env.GATSBY_DEVELOPER_PORTAL_URL,
  helpPortal: process.env.GATSBY_HELP_PORTAL_URL,
  privacyUrl: process.env.GATSBY_PRIVACY_DOC_URL,
  profileManagerUrl: process.env.GATSBY_PROFILE_MANAGER_URL,
  topconHomeUrl: process.env.GATSBY_TOPCON_HOME,
  landingSiteUrl: process.env.GATSBY_LANDING_SITE_URL,
  feedAppGooglePlayUrl: process.env.GATSBY_FEED_APP_PLAY_STORE_URL,
  feedAppAppleStoreUrl: process.env.GATSBY_FEED_APPLE_STORE_URL,
  aftermarketPdfUrl: process.env.GATSBY_AFTERMARKET_PDF_URL,
  feedPdfUrl: process.env.GATSBY_FEED_PDF_URL,
};

export const ENV_VARS = {
  recaptchaKey: process.env.GATSBY_RECAPTCHA_KEY,
};

export const ENTITIES = {
  dealer: 'dealer',
  farmer: 'farmer',
  agronomist: 'agronomist',
  dairyFarmer: 'dairyFarmer',
};

export const SOLUTIONS_HOME_CARDS = {
  crops: {
    id: 'crops',
    labelKey: 'app:nav:crops',
    linkTo: '/crops/',
    image: 'crops-solutions.png',
  },
  livestock: {
    id: 'livestock',
    labelKey: 'app:nav:livestock',
    linkTo: '/livestock/',
    image: 'livestock-solutions.png',
  },
};

export const NAV_ITEMS = {
  home: { id: 'home', labelKey: 'app:nav:home', linkTo: '/' },
  about: { id: 'about', labelKey: 'app:nav:about', linkTo: '/about/' },
  contactUs: { id: 'contact-us', labelKey: 'app:nav:contact_us', linkTo: '/contact-us/' },
  ...SOLUTIONS_HOME_CARDS,
};

export const LANGUAGES = [
  { labelKey: 'cs', value: 'cs' },
  { labelKey: 'da', value: 'da' },
  { labelKey: 'de', value: 'de' },
  { labelKey: 'en', value: 'en' },
  { labelKey: 'es', value: 'es' },
  { labelKey: 'fr', value: 'fr' },
  { labelKey: 'hu', value: 'hu' },
  { labelKey: 'it', value: 'it' },
  { labelKey: 'ja', value: 'ja' },
  { labelKey: 'nl', value: 'nl' },
  { labelKey: 'pl', value: 'pl' },
  { labelKey: 'pt', value: 'pt' },
  { labelKey: 'ru', value: 'ru' },
  { labelKey: 'tr', value: 'tr' },
];

export const SECTIONS_IDS = {
  dealer: 'dealer',
  agronomist: 'agronomist',
  dairyFarmer: 'dairyFarmer',
  farmer: 'farmer',
  opinions: 'opinions',
  partners: 'partners',
  solutions: 'solutions',
  products: 'products',
  productFeatures: 'productFeatures',
  addons: 'addons',
  explore: 'explore',
};

export const contactReasons = [
  { labelKey: 'app:contact-us:form:reason1', value: 'COMMERCIAL' },
  { labelKey: 'app:contact-us:form:reason2', value: 'TECHNICAL' },
  { labelKey: 'app:contact-us:form:reason3', value: 'SUPPORT' },
  { labelKey: 'app:contact-us:form:reason4', value: 'OTHER' },
];

export const PAGE_OG_IMAGES = {
  about: 'og-aboutus.jpg',
  agronomist: 'og-agronomist.jpg',
  'contact-us': 'og-contactus.jpg',
  dealer: 'og-dealer.jpg',
  farmer: 'og-farmer.jpg',
  dairyFarmer: 'og-dairyFarmer.jpg',
  home: 'og-landing.jpg',
};
