import React from 'react';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { COLORS } from '../../assets/styles/variables';
import { NAV_ITEMS } from '../../config/constants';
import ForwardLink from '../../molecules/ForwardLink';

import { phone } from '../../utils/media';

const FooterMenuWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
  height: auto;
  text-align: left;

  ${phone(css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    text-align: center;
  `)}
`;

const FooterNavLink = styled(ForwardLink)`
  padding: 15px;
`;

const FooterNav = ({ t }) => {
  return (
    <FooterMenuWrapper>
      <FooterNavLink color={COLORS.txtLight} linkTo={NAV_ITEMS.home.linkTo}>
        {t(NAV_ITEMS.home.labelKey)}
      </FooterNavLink>
      <FooterNavLink color={COLORS.txtLight} linkTo={NAV_ITEMS.crops.linkTo}>
        {t(NAV_ITEMS.crops.labelKey)}
      </FooterNavLink>
      <FooterNavLink color={COLORS.txtLight} linkTo={NAV_ITEMS.livestock.linkTo}>
        {t(NAV_ITEMS.livestock.labelKey)}
      </FooterNavLink>
      <FooterNavLink color={COLORS.txtLight} linkTo={NAV_ITEMS.contactUs.linkTo}>
        {t(NAV_ITEMS.contactUs.labelKey)}
      </FooterNavLink>
      <FooterNavLink color={COLORS.txtLight} linkTo={NAV_ITEMS.about.linkTo}>
        {t(NAV_ITEMS.about.labelKey)}
      </FooterNavLink>
    </FooterMenuWrapper>
  );
};

FooterNav.propTypes = {};

export default withTranslation()(FooterNav);
