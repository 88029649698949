import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { PAGE_OG_IMAGES } from '../config/constants';
import SEO from '../molecules/SEO';
import SideBar from '../molecules/SideBar';

export const MyContext = React.createContext();

const PageWrap = styled.div`
  margin: 0;
`;

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

// create a navigation component that wraps the burger menu
const Navigation = (props) => {
  const ctx = useContext(MyContext);

  return (
    <SideBar
      pageWrapId={'PageWrap'}
      outerContainerId={'MainWrap'}
      customBurgerIcon={false}
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
      left
      {...props}
    />
  );
};

const App = ({ children, ...props }) => {
  const pathname = props.location.pathname.slice(1, -1) || 'home';
  const image = PAGE_OG_IMAGES[pathname];

  return (
    <MyProvider>
      <PageWrap>
        <SEO pageTitle={pathname} description="" pathname={pathname} image={image} />
        <Navigation {...props} />
        {children}
      </PageWrap>
    </MyProvider>
  );
};
App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
