import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../assets/styles/variables';
import LanguageSelector from '../../molecules/LanguageSelector';
import { phone } from '../../utils/media';
import FooterCopyright, { MobileFooterCopyright } from './FooterCopyright';
import FooterNav from './FooterNav';
import FooterSocials from './FooterSocials';
import FooterTapLogo from './FooterTapLogo';

const FooterWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin-top: ${({ spaced }) => (spaced ? '100px' : '0')};
  grid-template-columns: 30% 70%;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  padding: 50px 3% 30px;
  background-color: ${COLORS.bgDark};
  color: ${COLORS.txtLight};
  position: relative;
  ${phone(css`
    display: none;
  `)}
`;

const MobileFooterWrapper = styled.div`
  display: none;
  ${phone(css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5%;
    background-color: ${COLORS.bgDark};
    color: ${COLORS.txtLight};
    position: relative;
  `)}
`;

const FooterSelectorWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 3%;
`;

const Footer = ({ spaced }) => {
  return (
    <FooterWrapper spaced={spaced}>
      <FooterSelectorWrapper>
        <LanguageSelector />
      </FooterSelectorWrapper>

      <FooterTapLogo />
      <FooterCopyright />
      <FooterNav />
      <FooterSocials />
    </FooterWrapper>
  );
};

export const MobileFooter = ({ spaced }) => {
  return (
    <MobileFooterWrapper spaced={spaced}>
      <FooterTapLogo />
      <LanguageSelector />

      <FooterNav />
      <FooterSocials />
      <FooterCopyright />
      <MobileFooterCopyright />
    </MobileFooterWrapper>
  );
};

MobileFooter.propTypes = {
  spaced: PropTypes.bool,
};

Footer.propTypes = {
  spaced: PropTypes.bool,
};

export default Footer;
