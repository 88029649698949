import styled from 'styled-components';

export const BtnTypes = {
  primary: 'primary',
  secondary: 'secondary',
  outlined: 'outlined',
  outlinedSecondary: 'outlined-secondary',
  googlePlay: 'google-play',
  appStore: 'app-store',
};

export const CenteredRowFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const CenteredRowGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const CenteredColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  width: 100%;
  height: 100%;
`;
