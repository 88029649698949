import React from 'react';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Text from '../../atoms/Text';
import { phone } from '../../utils/media';

const FooterCopyrightWrapper = styled.div`
  margin-top: 40px;
  letter-spacing: 2px;
  ${phone(css`
    display: none;
  `)}
`;

const MobileFooterCopyrightWrapper = styled(FooterCopyrightWrapper)`
  display: none;

  ${phone(css`
    margin-top: 15px;
    display: block;
    text-align: center;
  `)}
`;

const FooterCopyright = ({ t }) => {
  return (
    <FooterCopyrightWrapper>
      <Text block weight={'bold'} size={'10px'}>
        All rights reserved.
      </Text>
      <Text block weight={'bold'} size={'10px'}>
        ©2019 Topcon Corporation.
      </Text>
      <Text block weight={'bold'} size={'10px'}>
        Topcon Positioning Group.
      </Text>
    </FooterCopyrightWrapper>
  );
};

const MobileCopyRight = ({ t }) => {
  return (
    <MobileFooterCopyrightWrapper>
      <Text block weight={'bold'} size={'12px'}>
        All rights reserved.
      </Text>
      <Text block weight={'bold'} size={'12px'}>
        ©2019 Topcon Corporation.
      </Text>
      <Text block weight={'bold'} size={'12px'}>
        Topcon Agriculture is a division of Topcon Positioning Group.
      </Text>
    </MobileFooterCopyrightWrapper>
  );
};

FooterCopyright.propTypes = {};

export const MobileFooterCopyright = withTranslation()(MobileCopyRight);
export default withTranslation()(FooterCopyright);
