import { css } from 'styled-components';

export const mobile = (inner) => css`
  @media (max-width: 1023px) {
    ${inner}
  }
`;

export const phone = (inner) => css`
  @media (max-width: ${650 / 16}em) {
    ${inner}
  }
`;

export const computer = (inner) => css`
  @media (min-width: 1024px) {
    ${inner}
  }
`;
