import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../assets/styles/variables';
import Text from '../../atoms/Text';
import { mobile } from '../../utils/media';

const TextTitle = styled(Text)`
  font-size: ${({ size }) => size ?? '50px'};
  line-height: 1.4;
  ${mobile(css`
    font-size: 35px;
  `)}
`;

const CTABannerMainText = ({ children, ...props }) => (
  <TextTitle
    color={props.color ?? COLORS.txtLight}
    weight={props.weight ?? 'bold'}
    transform={props.transform ?? 'uppercase'}
    block
    {...props}
  >
    {children}
  </TextTitle>
);

CTABannerMainText.propTypes = {
  children: PropTypes.string.isRequired,
};

CTABannerMainText.defaultProps = {};

export default CTABannerMainText;
