import * as PropTypes from 'prop-types';
import React from 'react';
import Text from '../../atoms/Text';
import styled, { css } from 'styled-components';
import { mobile } from '../../utils/media';

const SectionHeaderText = styled(Text)`
  ${mobile(css`
    font-size: 24px;
  `)}
`;

const SectionHeaderMainText = ({ children, ...props }) => (
  <SectionHeaderText
    lineHeight={props.lineHeight ?? '1.6'}
    height={props.height ?? 'auto'}
    weight={props.weight ?? 'light'}
    transform={props.transform ?? 'uppercase'}
    block
    size={props.size ?? '32px'}
    {...props}
  >
    {children}
  </SectionHeaderText>
);

SectionHeaderMainText.propTypes = {
  children: PropTypes.any.isRequired,
};

SectionHeaderMainText.defaultProps = {};

export default SectionHeaderMainText;
