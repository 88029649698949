import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { CenteredRowFlex } from '../assets/styles/Utils';
import { COLORS } from '../assets/styles/variables';

export const navBorderHeight = '5px';

const LinkEl = styled(Link)`
  display: block;
  height: 100%;
`;

const NavItemContainer = styled(CenteredRowFlex)`
  padding: ${navBorderHeight} 20px 0;
  transition: all 300ms;
  border-bottom: ${navBorderHeight} solid transparent;
  text-transform: uppercase;
  cursor: pointer;

  &.selected {
    border-bottom: ${navBorderHeight} solid ${COLORS.bgLight};
  }

  &:hover {
    border-bottom: ${navBorderHeight} solid ${COLORS.bgLight};
  }
`;

const NavItem = ({ linkTo, children, isSelected }) => {
  return (
    <LinkEl to={linkTo}>
      <NavItemContainer className={isSelected ? 'selected' : ''}>{children}</NavItemContainer>
    </LinkEl>
  );
};

NavItem.propTypes = {
  linkTo: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

NavItem.defaultProps = {};

export default NavItem;
