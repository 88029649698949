import { Link } from 'gatsby';
import React from 'react';
import { slide as BurgerMenu } from 'react-burger-menu';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { COLORS } from '../assets/styles/variables';
import FontIcon from '../atoms/FontIcon';
import { NAV_ITEMS, URLS } from '../config/constants';
import { mobile } from '../utils/media';

const StyledBurgerMenu = styled.div`
  display: none;
  ${mobile(css`
    display: block;
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    bottom: 0;

  .bm-item {
    text-decoration: none;
    transition: color 0.2s;
  }

  .bm-item:hover {
    color: white;
  }

  .bm-burger-button {
    position: fixed;
    width: 30px;
    height: 20px;
    right: 2vw;
    top: 3.5vh;
  }

  .bm-burger-bars {
    background: ${COLORS.bgLight};
    padding: 2px 0;
  }

  .bm-cross-button {
    display: none;
    height: 30px;
    width: 15px;
  }

  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu {
    background-color: ${COLORS.bgDark};
  }

  .bm-morph-shape {
    fill: ${COLORS.bgLight};
  }

  .bm-item-list {
    color: ${COLORS.bgDark};
  }

  .bm-overlay {
    top: 70px;
  }

  .bm-menu-wrap {
    top: 70px;
  }
  `)}
`;

const Navitem = styled(Link)`
  padding: 20px 0 20px 20px;
  border: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.bgLight};
  text-align: left;
  display: inline-block;
  transition: color 0.2s;
  background-color: ${COLORS.bgDark};
  outline: none;
`;

const LinkText = styled.span`
  font-weight: 200;
  text-decoration: none;
  color: ${COLORS.bgLight};
  font-size: 24px;
  text-transform: uppercase;
`;

const DropdownItem = styled.div`
  padding: 20px 0 20px 20px;
  border: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.bgLight};
  text-align: left;
  display: inline-block;
  transition: color 0.2s;
  background-color: ${COLORS.bgDark};
  outline: none;
  cursor: pointer;
`;

const DropdownIcon = styled(FontIcon)`
  display: none;
  ${mobile(css`
    font-size: 20px;
    display: inline-block;
    margin-left: 15px;
  `)}
`;

const DropdownLinkText = styled(Link)`
  flex: 1;
  font-weight: 200;
  text-decoration: none;
  color: ${COLORS.bgLight};
  font-size: 18px;
  text-transform: uppercase;
  padding: 10px 0 10px 40px;
`;
const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
`;


// const onSignup = () => window.open(URLS.signup, '_BLANK');
const SideBar = ({ t, loginCb, signupCb, ...rest }) => {
  const [state, setState] = React.useState(false);
  const onLogin = () => {
    return window.open(URLS.login, '_BLANK');
  };
  return (
    <StyledBurgerMenu>
      <BurgerMenu width='100%' {...rest}>
        <Navitem to={NAV_ITEMS.home.linkTo}>
          <LinkText to={NAV_ITEMS.home.linkTo}>{t(NAV_ITEMS.home.labelKey)}</LinkText>
        </Navitem>

        <DropdownItem to='' onClick={() => setState(!state)}>
          <LinkText to={'#'}>{t('app:nav:solutions')}</LinkText>

          {state ? (
            <DropdownIcon icon='arrow2-up-1' color={'#fff'} />
          ) : (
            <DropdownIcon icon='arrow2-down' color={'#fff'} />
          )}
          {state && (
            <DropdownList>
              <DropdownLinkText to={NAV_ITEMS.crops.linkTo}>{t(NAV_ITEMS.crops.labelKey)}</DropdownLinkText>
              <DropdownLinkText to={NAV_ITEMS.livestock.linkTo}>{t(NAV_ITEMS.livestock.labelKey)}</DropdownLinkText>
            </DropdownList>
          )}
        </DropdownItem>

        <Navitem to={NAV_ITEMS.contactUs.linkTo}>
          <LinkText to={NAV_ITEMS.contactUs.linkTo}>{t(NAV_ITEMS.contactUs.labelKey)}</LinkText>
        </Navitem>
        <Navitem to={NAV_ITEMS.about.linkTo}>
          <LinkText to={NAV_ITEMS.about.linkTo}>{t(NAV_ITEMS.about.labelKey)}</LinkText>
        </Navitem>
        <Navitem onClick={onLogin} to={'#'}>
          <LinkText onClick={onLogin}>{t('app:login')}</LinkText>
        </Navitem>
      </BurgerMenu>
    </StyledBurgerMenu>
  );
};

export default withTranslation()(SideBar);
