import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../assets/styles/variables';

const TextEl = styled.span`
  display: ${({ block }) => (block ? 'block' : '')};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  line-height: ${({ lineHeight }) => lineHeight};
  height: ${({ height }) => height};
  text-transform: ${({ transform }) => transform};
  text-shadow: ${({ withShadow }) => (withShadow ? '0 1px 2px ' + COLORS.shadowDark : '')};
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
`;

const Text = ({ children, ...props }) => <TextEl {...props}>{children}</TextEl>;

Text.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  block: PropTypes.bool,
  weight: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.string,
  withShadow: PropTypes.bool,
  lineHeight: PropTypes.any,
  transform: PropTypes.string,
  align: PropTypes.string,
};

Text.defaultProps = {};

export default Text;
