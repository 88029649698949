export const SOCIAL_ITEMS = {
  youtube: {
    iconName: 'social-youtube',
    socialLink: 'https://www.youtube.com/user/TopconPrecisionAg',
  },
  facebook: {
    iconName: 'social-facebook',
    socialLink: 'https://www.facebook.com/TopconAg',
  },
  linkedin: {
    iconName: 'social-linkedln',
    socialLink: 'https://www.linkedin.com/company/topconagriculture/',
  },
  twitter: {
    iconName: 'twitter-logo-button',
    socialLink: 'https://twitter.com/topconag',
  },
  instagram: {
    iconName: 'instagram',
    socialLink: 'https://www.instagram.com/topconag',
  },
};
