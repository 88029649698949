import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from '../assets/styles/variables';
import { LANGUAGES, URLS } from '../config/constants';

const Language = styled.div``;

const LanguageSelect = styled.select`
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${COLORS.bgLight};
  box-shadow: none;
  cursor: pointer;
  color: ${COLORS.txtLight};
  outline: none;

  option {
    color: ${COLORS.txtDark};
  }
`;

const FlagImage = styled.img`
  width: 35px;
  position: relative;
  margin-right: 15px;
  border-radius: 2px;
  top: 8px;
`;

const LanguageSelector = ({ t }) => {
  const [language, setLanguage] = useState('');
  useEffect(() => {
    setLanguage(localStorage.getItem('i18nextLng'));
  }, [setLanguage, typeof window !== 'undefined' && localStorage.getItem('i18nextLng')]);

  const onChangeLanguage = (event) => {
    i18next.changeLanguage(event.target.value, (err) => {
      setLanguage(event.target.value);
    });
  };

  const languagesOptions = LANGUAGES.map((option) => (
    <option key={option.value} value={option.value}>
      {t(`app:languages:${option.labelKey}`)}
    </option>
  ));

  return (
    <Language>
      <FlagImage alt={'language'} src={`${URLS.resources}images/common/flags/flag_${language || 'en'}.svg`} />
      <LanguageSelect required name={'reason'} onChange={onChangeLanguage} value={language}>
        {languagesOptions}
      </LanguageSelect>
    </Language>
  );
};

LanguageSelector.propTypes = {};

export default withTranslation()(LanguageSelector);
